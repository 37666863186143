import { useState, useEffect } from "react";
import {
  Table,
  Space,
  message,
  Tag,
  Switch,
  Drawer,
  Button,
  Typography,
  Modal,
  Input,
  Form,
  DatePicker,
} from "antd";

import useRestaurants from "../../hooks/useRestaurants";
import { downloadCsv, parser, v3Axios } from "../../utils";
import {
  DownloadOutlined,
  ExpandOutlined,
  StarFilled,
  SyncOutlined,
  UserOutlined,
  UpOutlined,
} from "@ant-design/icons";
import useSearch from "../../components/inventory/utils/useSearch";
import { compressedImage } from "../../common/utils";
import RestaurantLinks from "../../components/Common/RestaurantLinks";
import styled from "styled-components";
import Parse from "parse";
import { useHistory } from "react-router-dom";
import { getParams } from "../../common/utils";
import { Link } from "react-router-dom";
import AddNew from "./AddNew";
import axios from "axios";
import moment from "moment";
import { AiOutlinePushpin, AiFillPushpin } from "react-icons/ai";
import DeliveryAreaRestaurant from "./DeliveryArea";
import { ExpireDate } from "../../components/restaurant/mou";

const { Text } = Typography;
const { Item } = Form;

const Availability = ({ object, children }) => {
  const [availability, setAvailability] = useState(object?.get("availability"));
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Switch
        checked={availability}
        disabled={loading}
        onChange={async (e) => {
          try {
            if (object instanceof Parse.Object) {
              console.log("avaibality triggered", object);
              setAvailability(e);
              setLoading(true);
              object.set("availability", e);
              object.set("toggledAt", new Date());
              const res = await object.save(null, {
                sessionToken: Parse.User.current()?.getSessionToken(),
              });

              if (res) {
                message.success(
                  `Availability updated to ${e ? "Available" : "Unavailable"}`
                );
              }

              setLoading(false);
              if (!res) {
                setAvailability(!e);
              }
            }
          } catch (err) {
            setLoading(false);
            setAvailability(!e);
            message.error(err.message);
          }
        }}
      />
      {children}
    </div>
  );
};

function Review({ resId }) {
  const [reviews, setReviews] = useState({
    data: [],
    loading: false,
    rating: {},
  });

  const fetchData = async (id) => {
    try {
      setReviews((prev) => ({ ...prev, loading: true }));
      const { data } = await axios.get(
        `https://apiv2.munchies.com.bd/api/rating/restaurant/${id}`,
        { timeout: 30 * 1000 }
      );
      setReviews(data);
    } catch (err) {
      message.error(err.message);
      setReviews((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchData(resId);
  }, [resId]);

  return (
    <div>
      {reviews.loading && <p>Loading...</p>}
      {reviews.data?.map((review, idx) => {
        return (
          <ReviewItem key={idx}>
            <div className="wrap">
              <h3 className="name">
                <div className="icon">
                  <UserOutlined />
                </div>
                <div>{review.userName}</div>
                {/* <div style={{ marginLeft: "auto" }}>
                  <Switch checked={review.isActive} />
                </div> */}
              </h3>
              {review.review && <div>{review.review}</div>}
              <div>
                {[1, 2, 3, 4, 5].map((n) => (
                  <StarFilled
                    key={n}
                    style={{ color: review.rating >= n ? "#faad14" : "gray" }}
                  />
                ))}
              </div>
              <div className="time">
                {new Date(review.createdAt).toLocaleString()}
              </div>
            </div>
          </ReviewItem>
        );
      })}
    </div>
  );
}

const ReviewItem = styled.div`
  padding: 5px 10px;
  .wrap {
    background: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px;

    .name {
      display: flex;
      align-items: center;
      .icon {
        background: #bfbfbf;
        color: black;
        padding: 5px;
        border-radius: 50%;
        margin-right: 10px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .time {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const getPinnedRestaurants = () => {
  try {
    const list = localStorage.getItem("pinned-restaurants");
    if (list) {
      return JSON.parse(list);
    }
    return [];
  } catch (err) {
    return [];
  }
};

export default function List() {
  const { getRestaurants } = useRestaurants();
  const [restaurants, setRestaurants] = useState({
    count: 0,
    results: [],
  });
  const [form] = Form.useForm();

  const [, getColumnsSearchProps] = useSearch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = getParams(window.location.search);
  const [restaurant, setRestaurant] = useState(null);
  const [review, setReview] = useState(null);
  const [hubs, setHubs] = useState([]);
  let [reqPayloads, setReqPayloads] = useState({
    limit: 100,
    skip: 0,
    select: [
      "name",
      "commission_type",
      "type",
      "sorting_order",
      "banner_image",
      "cuisines",
      "availability",
      "commission",
      "counter",
      "phone",
      "hub.name",
      "toggledAt",
      "rating",
      "offBy.name",
      "areas",
      "offBy.type",
      "operating_hours",
      "mou",
      "mou_expired_date",
    ],
    sortBy: "createdAt",
    sortOrder: "desc",
    ...params,
    pinned: getPinnedRestaurants(),
  });
  const [pinnedRestaurants, setPinnedRestaurants] = useState(
    getPinnedRestaurants() || []
  );

  const fetchRestaurants = (params) => {
    setLoading(true);
    getRestaurants(params, (err, data) => {
      if (data) {
        setRestaurants({
          count: data.count,
          results: parser(data.results).map((res) => ({
            ...res,
            pinned: pinnedRestaurants.includes(res.id),
          })),
        });
      }

      if (err) {
        message.error(err);
      }
      setLoading(false);
    });
  };

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubs(parser(hubs));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    const fetchLayers = async () => {
      try {
        const { data } = await v3Axios.get("/api/v1/areas");

        const zoneLists = {};

        for (const item of data) {
          if (!zoneLists[item?.zone._id]) {
            zoneLists[item?.zone._id] = {
              ...item.zone,
              areas: [],
            };
          }
          zoneLists[item?.zone._id].areas.push(item);
        }
        setZones(Object.values(zoneLists));
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    };
    fetchHubs();
    fetchLayers();
  }, []);

  useEffect(() => {
    fetchRestaurants(reqPayloads);
    const searchParams = {};

    const { select, ...params } = reqPayloads;

    for (let i in params) {
      if (params[i] !== undefined && params[i] !== null) {
        if (!Array.isArray(params[i])) {
          searchParams[i] = params[i];
        } else if (Array.isArray(params[i]) && params[i].length > 0) {
          searchParams[i] = params[i];
        }
      }
    }

    for (let i in searchParams) {
      if (Array.isArray(searchParams[i])) {
        searchParams[i] = JSON.stringify(searchParams[i]);
      }
    }
    history.push(
      `/restaurant/list?${new URLSearchParams(searchParams).toString()}`
    );
  }, [reqPayloads]);

  const TableText = ({ children }) => (
    <span style={{ fontSize: "16px", fontWeight: "500", color: "black" }}>
      {children}
    </span>
  );

  const filterFormate = (value) => {
    return Array.isArray(value) ? value : value ? [value] : value;
  };

  const handlePin = (id, pinned) => {
    if (pinned && !pinnedRestaurants?.includes(id)) {
      pinnedRestaurants.push(id);
    } else if (!pinned && pinnedRestaurants?.includes(id)) {
      pinnedRestaurants.splice(pinnedRestaurants.indexOf(id), 1);
    }

    restaurants.results.forEach((r) => {
      if (r.id === id) {
        r.pinned = pinned;
      }
    });

    setPinnedRestaurants([...pinnedRestaurants]);
    setRestaurants({ ...restaurants });

    localStorage.setItem(
      "pinned-restaurants",
      JSON.stringify(pinnedRestaurants)
    );
  };

  const downloadInfo = async () => {
    getRestaurants(
      {
        select: [
          "name",
          "email",
          "phone",
          "operating_hours",
          "hub.name",
          "commission",
        ],
        limit: 1000,
      },
      (err, data) => {
        if (err) {
          message.error(err);
        } else {
          const csvData = data.results.map((res) => {
            return {
              name: res.get("name").replaceAll(",", " "),
              email: res.get("email"),
              phone: res.get("phone"),
              commission: res.get("commission"),
              operating_hours: res
                .get("operating_hours")
                .map(
                  (hour) =>
                    (hour % 12 === 0 ? "12" : hour % 12) +
                    (hour >= 12 ? " PM" : " AM")
                )
                .join(" to "),
              hub: res.get("hub")?.name,
              createdAt: new Date(res.createdAt)
                .toLocaleString()
                .replaceAll(", ", " "),
            };
          });

          downloadCsv(
            [
              {
                header: [
                  {
                    title: "Name",
                    key: "name",
                  },
                  {
                    title: "Phone",
                    key: "phone",
                  },
                  {
                    title: "Commission",
                    key: "commission",
                  },
                  {
                    title: "Hub",
                    key: "hub",
                  },
                  {
                    title: "Email",
                    key: "email",
                  },
                  {
                    title: "Operating Hours",
                    key: "operating_hours",
                  },
                  {
                    title: "Created At",
                    key: "createdAt",
                  },
                ],
                data: csvData,
              },
            ],
            "restaurants"
          );
        }
      }
    );
  };

  function localDate(date) {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  const columns = [
    {
      title: "Onboard",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => localDate(date),
    },

    {
      title: "Banner",
      dataIndex: "banner_image",
      key: "banner_image",
      width: "150px",
      render: (image, { id }) => (
        <div className="table-image">
          <img src={compressedImage(image)} width={100} height={75} alt="" />
          <div className="icon" onClick={() => setRestaurant(id)}>
            <ExpandOutlined className="ex" />
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      defaultFilteredValue: filterFormate(reqPayloads.name),
      className: "drag-visible",
      ...getColumnsSearchProps("name"),
      render: (text, { id }) => {
        return (
          <>
            <RestaurantLinks id={id}>
              <TableText>{text}</TableText>
            </RestaurantLinks>
            <Text style={{ color: "gray", fontSize: "12px" }} copyable>
              {id}
            </Text>
          </>
        );
      },
    },
    {
      title: "OperatingHours",
      dataIndex: "operating_hours",
      key: "operating_hours",
      width: "200px",
      render: (hours) => (
        <div style={{ fontSize: "12px", color: "gray" }}>
          {hours
            .map(
              (hour) =>
                (hour % 12 === 0 ? "12" : (hour % 12).toFixed(2)) +
                (hour >= 12 ? " PM" : " AM")
            )
            .join(" to ")
            .replaceAll(".", ":")}
        </div>
      ),
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      width: "160px",
      filters: hubs.map((hub) => ({
        text: hub.name,
        value: hub.id,
      })),
      render: (hub) => (
        <Link to={`/hub/create-new?id=${hub?.id}`}>
          <div style={{ fontSize: "14px", color: "gray" }}>
            {hub?.get("name")}
          </div>
        </Link>
      ),
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      width: "150px",
      defaultFilteredValue: filterFormate(reqPayloads.availability),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.availability === value,
      render: (availability, { ref, offBy, toggledAt }) => (
        <Availability object={ref}>
          {!availability && offBy && (
            <div>
              <Tag color="red" style={{ marginTop: "5px" }}>
                {offBy.get("name") + "(" + offBy.get("type") + ")"}
                {
                  <Typography.Paragraph style={{ color: "gray" }}>
                    {toggledAt &&
                      moment(toggledAt).format("DD/MM/YYYY hh:mm A")}
                  </Typography.Paragraph>
                }
              </Tag>
            </div>
          )}
        </Availability>
      ),
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      width: "150px",
      sorter: (a, b) => a.commission - b.commission,
      render: (text, { commission_type: cty }) => (
        <Space>
          {text}{" "}
          <Tag color={cty === "flat" ? "green" : "orange"}>
            {cty === "percentage" ? "%" : cty}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "150px",
      filters: [
        { text: "Restaurant", value: "restaurant" },
        { text: "Store", value: "store" },
        { text: "Sub store", value: "sub_store" },
      ],
      onFilter: (value, record) => record.type === value,
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "200px",
      defaultFilteredValue: filterFormate(reqPayloads.phone),
      ...getColumnsSearchProps("phone"),
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: "MOU",
      dateIndex: "mou_expired_date",
      key: "mou_expired_date",
      width: "200px",
      render: ({ mou_expired_date }) => (
        <>{mou_expired_date && <ExpireDate date={mou_expired_date} />}</>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: "150px",
      render: (rating, { id }) =>
        rating?.value > 0 ? (
          <Space direction="vertical">
            <div>
              Rating: {(rating.value / rating.count).toFixed(2)} /{" "}
              {rating.count}
            </div>
            <Button onClick={() => setReview(id)}>Review</Button>
          </Space>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Pin",
      key: "pin",
      width: "150px",
      render: ({ id, pinned }) => {
        if (pinned) {
          return (
            <AiFillPushpin
              className="pin"
              onClick={() => handlePin(id, false)}
            />
          );
        }
        return (
          <AiOutlinePushpin
            onClick={() => handlePin(id, true)}
            className="pin"
          />
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const onSelectChange = (newSelectedRowKeys, items) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedItems(items.map((i) => i.id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  let [formData, setFormData] = useState({});
  const showVoucherModal = () => {
    setIsVoucherModalOpen(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleVoucherModalClose = () => {
    setIsVoucherModalOpen(false);
  };
  // Voucher Okay
  const handleVoucherOk = async () => {
    try {
      const voucherData = form.getFieldsValue();

      if (voucherData.voucher_title_validity) {
        voucherData.voucher_title_validity = new Date(
          voucherData.voucher_title_validity
        );
      } else {
        voucherData.voucher_title_validity = null;
      }
      if (voucherData.voucher_sub_title_validity) {
        voucherData.voucher_sub_title_validity = new Date(
          voucherData.voucher_sub_title_validity
        );
      } else {
        voucherData.voucher_sub_title_validity = null;
      }

      const items = await new Parse.Query("restaurant")
        .select(["objectId"])
        .containedIn("objectId", selectedItems)
        .find();

      items.forEach(async (item) => {
        item.set("voucher_title", voucherData.voucher_title);
        item.set("voucher_sub_title", voucherData.voucher_sub_title);
        item.set("voucher_title_validity", voucherData.voucher_title_validity);
        item.set(
          "voucher_sub_title_validity",
          voucherData.voucher_sub_title_validity
        );
      });

      await Parse.Object.saveAll(items);
      setIsVoucherModalOpen(false);
    } catch (error) {
      message.error(error);
    }
  };

  const handleOk = async (type) => {
    for await (const key of selectedRowKeys) {
      const res = restaurants.results.find((res) => res.sorting_order === key);

      const query = new Parse.Query("restaurant")
        .equalTo("objectId", res.id)
        .include("areas");

      const restaurant = await query.first();

      if (restaurant) {
        const restaurantAreas = restaurant.get("areas") || [];

        let newAreas = [];

        if (type === "add") {
          newAreas = restaurantAreas.concat(areas);
        } else if (type === "remove") {
          newAreas = restaurantAreas.filter((area) => !areas.includes(area));
        }
        restaurant.set("skip_aftersave", true);
        restaurant.set("areas", [...new Set(newAreas)]);
        await restaurant.save(null, {
          sessionToken: Parse.User.current()?.getSessionToken(),
        });
      }
    }

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [areas, setAreas] = useState([]);
  const [zones, setZones] = useState([]);

  const formOnChange = async () => {
    const formData = form.getFieldsValue();
    setFormData({ ...formData });
  };

  const submitVoucherHandler = async (values) => {
    if (values.voucher_title_validity) {
      values.voucher_title_validity = new Date(
        values.voucher_title_validity
      ).toISOString();
    }

    if (values.voucher_sub_title_validity) {
      values.voucher_sub_title_validity = new Date(
        values.voucher_sub_title_validity
      ).toISOString();
    }
  };
  return (
    <Wrapper>
      <div>
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          onClick={downloadInfo}
        >
          Download
        </Button>
        {selectedRowKeys.length > 0 && (
          <>
            <Button
              icon={<SyncOutlined />}
              type="primary"
              onClick={() => showModal()}
              style={{ marginLeft: "10px" }}
            >
              Update Selected Areas
            </Button>
            <Button
              icon={<UpOutlined />}
              type="primary"
              onClick={() => showVoucherModal()}
              style={{ marginLeft: "10px" }}
            >
              Update Voucher
            </Button>
          </>
        )}
      </div>
      <Table
        dataSource={Array.from(restaurants.results)}
        columns={columns}
        loading={loading}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: reqPayloads.limit,
          current: reqPayloads.skip / reqPayloads.limit + 1,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: restaurants.count,
          pageSizeOptions: ["10", "20", "50", "100", "200"],
          position: ["topLeft", "topRight"],
        }}
        rowKey="sorting_order"
        scroll={{ x: 1500 }}
        onChange={(pagination, filters) => {
          const params = {
            ...reqPayloads,
            limit: pagination.pageSize,
            skip: pagination.pageSize * (pagination.current - 1),
            name: filters.name ? filters.name[0] : undefined,
            phone: filters.phone ? filters.phone[0] : undefined,
            availability: filters.availability
              ? filters.availability
              : undefined,
            cuisines: filters.cuisines ? filters.cuisines[0] : undefined,
            hub: filters.hub,
            mou_expired_date: filters.mou_expired_date ?? "",
          };
          setReqPayloads(params);
        }}
      />

      <Modal
        title="Update Delivery Area on Selected Restaurants"
        visible={isModalOpen}
        width={1000}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit" type="primary" onClick={(e) => handleOk("add")}>
            Add
          </Button>,
          <Button key="link" type="danger" onClick={(e) => handleOk("remove")}>
            Remove
          </Button>,
        ]}
      >
        <DeliveryAreaRestaurant
          zones={zones}
          setAreas={setAreas}
          areas={areas}
        />
      </Modal>

      {/* Voucher Modal */}
      <Modal
        title="Update Voucher on selected Restaurants"
        visible={isVoucherModalOpen}
        width={500}
        onOk={handleVoucherOk}
        onCancel={handleVoucherModalClose}
        // footer={[
        //   <Button key="back" onClick={handleVoucherModalClose}>
        //     Return
        //   </Button>,
        //   <Button
        //     key="submit"
        //     type="primary"
        //     onClick={(e) => handleVoucherOk("add")}
        //   >
        //     Add
        //   </Button>,
        //   <Button
        //     key="link"
        //     type="danger"
        //     onClick={(e) => handleVoucherOk("remove")}
        //   >
        //     Remove
        //   </Button>,
        // ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={submitVoucherHandler}
          size="large"
          scrollToFirstError
          onChange={formOnChange}
        >
          {selectedRowKeys.length > 0 && (
            <div className="section">
              <h3 className="section-title">Voucher</h3>
              <Item name="voucher_title" label="Voucher Title">
                <Input placeholder="voucher: xyz" />
              </Item>
              <Item name="voucher_sub_title" label="Voucher Sub Title">
                <Input placeholder="voucher sub title" />
              </Item>
              <Item
                name="voucher_title_validity"
                label="Voucher Title Validity"
              >
                <DatePicker
                  format="DD-MM-YYYY HH:mm:ss"
                  showTime
                  disabledDate={(current) =>
                    moment().add(-1, "days") >= current
                  }
                />
              </Item>
              <Item
                name="voucher_sub_title_validity"
                label="Voucher Sub Title Validity"
              >
                <DatePicker
                  format="DD-MM-YYYY HH:mm:ss"
                  showTime
                  disabledDate={(current) =>
                    moment().add(-1, "days") >= current
                  }
                />
              </Item>
            </div>
          )}
        </Form>
      </Modal>

      <Drawer
        title="Restaurant"
        placement="right"
        visible={restaurant}
        onClose={() => setRestaurant(null)}
        width={1200}
      >
        {restaurant && <AddNew resId={restaurant} />}
      </Drawer>
      <Drawer
        title="Review"
        placement="right"
        visible={review}
        onClose={() => setReview(null)}
        width={500}
      >
        {review && <Review resId={review} />}
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .pin {
    font-size: 20px;
  }
`;
