export const compressedImage = (image) => {
  // const key = image?.split("/").pop();
  // if (process.env.ENVIORMENT === "dev") {
  //   return key;
  // } else {
  //   return `https://d258m6aalgmo9e.cloudfront.net/fit-in/160x120/${key}`;
  // }
  return image;
};

export const getParams = (url) => {
  const params = {};
  new URLSearchParams(url).forEach((value, key) => {
    if (value) {
      params[key] = Number.isInteger(Number(value)) ? Number(value) : value;
      if (Number.isInteger(Number(value))) {
        params[key] = Number(value);
      } else if (value[0] === "[" && value[value.length - 1] === "]") {
        params[key] = JSON.parse(value);
      } else {
        params[key] = value;
      }
    }
  });
  return params;
};

export const permission = (arg) => {
  arg = arg.toString();
  let user = ["admin", "manager", "csr"];
  let arr = [];

  for (let i in arg) {
    if (parseInt(arg[i]) === 1) {
      arr.push(user[i]);
    }
  }

  return arr;
};

export const dayStartHour = (date) => {
  const time = new Date(date);
  if (time.toString() === "Invalid Date") {
    throw new Error("Invalid date");
  }
  time.setHours(9, 0, 0, 0);
  return time;
};

export const getImageKey = (url) => {
  return url?.split("/").slice(3).join("/");
};

export const isFloat = (n) => Number(n) === n && n % 1 !== 0;
