import { useState, useContext } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import Styled from "styled-components";
import AssignRider from "./AssignRider";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import OrderReject from "../OrderReject";
import { AuthContext } from "../../../AuthProvider";
import { BiCycling } from "react-icons/bi";
import OrderCancel from "../OrderCancel";
import axios from "axios";
import Parse from "parse";

export default function FooterActions({ id, status, rider, ...rest }) {
  const [assignRiderBox, setAssignRiderBox] = useState(false);
  const {
    login: { data },
  } = useContext(AuthContext);
  const { updateStatus } = useContext(OrdersContext);
  const [loading, setLoading] = useState([false, ""]);
  const [rejectBox, setRejectBox] = useState(false);
  const [cancelBox, setCancelBox] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const updateOrderHandler = (status) => {
    setLoading([true, status]);
    updateStatus(
      {
        orderId: id,
        status,
      },
      async () => {
        if (status === "preparing" || status === "confirmed" || status === "ready" || status === "picked") {
          console.log(id);
          const query = await new Parse.Query("order").equalTo("objectId",id);

          const user = await query.first();
          const toCapitalize = status.charAt(0).toUpperCase() + status.slice(1);
          

          const notification = await axios.post(
            "https://dev2.munchies.com.bd/api/auth/v1/user/notify",
            {
              topic: [user.get("user").id],
              title: "Order Status",
              body: `Your order is now ${toCapitalize}`,
            }
          );
          if (notification) {
            console.log("Notification sent to user");
          }
        }

        setLoading([false, ""]);
        setMenuVisible(false);
      }
    );
  };

  const rejectBtnHandler = () => {
    setMenuVisible(false);
    setTimeout(() => {
      setRejectBox(true);
    }, 500);
  };

  const cancelledBtnHandler = () => {
    setMenuVisible(false);
    setTimeout(() => {
      setCancelBox(true);
    }, 500);
  };

  const StatusMenu = ({ status }) => {
    return (
      <StatusMenuWrapper
        style={{
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          minWidth: "160px",
        }}
      >
        {!["delivered", "rejected", "cancelled", "restaurant_reject"].includes(
          status
        ) && (
          <Menu.Item
            style={{ color: "red" }}
            key="1"
            onClick={(e) => cancelledBtnHandler()}
            icon={<CloseOutlined />}
          >
            Cancelled
          </Menu.Item>
        )}
        {![
          "rejected",
          "confirmed",
          "ready",
          "picked",
          "delivered",
          "pending",
        ].includes(status) && (
          <Menu.Item
            style={{ color: "green" }}
            key="2"
            onClick={(e) => updateOrderHandler("confirmed")}
            icon={<LikeOutlined />}
          >
            Confirmed
          </Menu.Item>
        )}
        {!["delivered", "cancelled", "created", "ready", "pending"].includes(
          status
        ) && (
          <Menu.Item
            style={{ color: "orange" }}
            key="3"
            onClick={(e) => updateOrderHandler("ready")}
            icon={<CheckCircleOutlined />}
          >
            Ready
          </Menu.Item>
        )}
        {![
          "delivered",
          "cancelled",
          "created",
          "preparing",
          "pending",
        ].includes(status) && (
          <Menu.Item
            style={{ color: "#0c2299" }}
            key="4"
            onClick={(e) => updateOrderHandler("preparing")}
            icon={<CheckCircleOutlined />}
          >
            Preparing
          </Menu.Item>
        )}
        {!["delivered", "cancelled", "created", "picked", "pending"].includes(
          status
        ) && (
          <Menu.Item
            style={{ color: "#ff9800" }}
            key="4"
            onClick={(e) => updateOrderHandler("picked")}
            icon={<BiCycling />}
          >
            Picked
          </Menu.Item>
        )}
        {![
          "rejected",
          "cancelled",
          "created",
          "delivered",
          "confirmed",
          "preparing",
          "pending",
        ].includes(status) && (
          <Menu.Item
            style={{ color: "teal" }}
            key="5"
            onClick={(e) => updateOrderHandler("delivered")}
            icon={<CheckOutlined />}
          >
            Delivered
          </Menu.Item>
        )}
        {![
          "delivered",
          "created",
          "rejected",
          "confirmed",
          "preparing",
          "cancelled",
          "pending",
        ].includes(status) && (
          <Menu.Item
            style={{ color: "red" }}
            key="6"
            onClick={rejectBtnHandler}
            icon={<CloseOutlined />}
          >
            Rejected
          </Menu.Item>
        )}
      </StatusMenuWrapper>
    );
  };

  return (
    <FooterActionsStyled className="footer-actions">
      <div className="left-side">
        {!["rejected", "cancel", "created"].includes(status) && (
          <>
            <div
              onClick={(e) => setAssignRiderBox(true)}
              className={`icon ${rider ? "active" : ""}`}
            >
              <BiCycling />{" "}
              <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                {rider ? rider.get("name") : ""}
              </span>
            </div>
            {assignRiderBox && (
              <AssignRider
                id={id}
                rider={rider}
                status={status}
                close={(e) => setAssignRiderBox(false)}
              />
            )}
          </>
        )}
      </div>

      <div className="btns">
        {status !== "restaurant_reject" && (
          <div className="status-bar">
            <Dropdown
              visible={menuVisible}
              trigger={["click"]}
              arrow
              overlay={<StatusMenu status={status} />}
              placement="top"
            >
              <Button
                disabled={
                  status === "delivered" ? data.type !== "admin" : false
                }
                onClick={() => setMenuVisible(!menuVisible)}
                loading={loading[0]}
                icon={<PlayCircleOutlined />}
                type="primary"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                {status}
              </Button>
            </Dropdown>
          </div>
        )}
      </div>

      <OrderReject
        visible={rejectBox}
        setVisible={setRejectBox}
        orderId={id}
        data={data}
      />
      <OrderCancel
        visible={cancelBox}
        setVisible={setCancelBox}
        orderId={id}
        data={data}
      />
    </FooterActionsStyled>
  );
}

const StatusMenuWrapper = Styled(Menu)`
overflow: hidden;
.ant-menu-item{
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    line-height: 20px;
    margin: 0!important;
    &:hover{
        background-color: #f5f5f5;
    }
}
`;

const FooterActionsStyled = Styled.div`
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .left-side{

        .icon{
            min-width: 50px;
            font-size: 22px;
            height: 33px;
            background: #fff;
            color: gray;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            transition: .3s;
            padding: 0 10px;
        
            &:hover{
                box-shadow: 0px 3px 11px rgba(0,0,0,.2);
            }
        }

        .icon.active{
            color: #e91e63;
        }


        .assign-rider{
            position: absolute;
            width: 95%;
            left: 2.5%;
            top: 10px;
            min-height: 180px;
            background: #ffffff;
            border-radius: 10px;
            padding: 20px;
            animation: fadeIn .2s ease-in;
            box-shadow: 0px 7px 28px rgba(0,0,0,.2); 

            .edit-btn{
                position: absolute;
                right: 20px;
                top: 20px;

                button{
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .rider-details{
                a{
                    color: black;
                }
            }


            .box-footer{
                text-align: right;
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                padding: 10px 20px;
            }
        }
    }

    .btns{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button{
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border-color: transparent;
        }

        .success{
            color: green;
            transition: .3s;

            &:hover{
                border-color: green;
            }
        }

        .processing{
            width: 50px;
            height: 35px;
            background: #fff;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            color: #e91e63;
        }
    }
`;
