import { Form, Modal, Button, message, Select, Input } from "antd";
import { useState } from "react";
import useOrders from "../../hooks/useOrders";
import axios from "axios";
import Parse from "parse";

export default function OrderCancel({ visible, setVisible, orderId,data}) {
  const { Item } = Form;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { updateStatus } = useOrders();
  const [exceptional, setExceptional] = useState(false);

  const reasons = [
    "Out of delivery zone",
    "Out of zone for security issue",
    "Customer didn't receive food due to late delivery",
    "Customer will not receive food due to late delivery",
    "Cancelled by customer",
    "Customer unreachable",
    "Customer unreachable after pickup",
    "Restaurant closed",
    "Restaurant unreachable",
    "Product unavailable",
    "Order by mistake",
    "Mind changed",
    "Wrong number",
    "Test order",
    "Area restricted",
    "Road blocked",
    "Operation closed",
    "Customer want's to get delivery in short period of time",
    "Double order",
    "Rider shortage",
    "Exceptional",
  ];

  const onFinish = async (values) => {
    setLoading(true);
    updateStatus(
      {
        orderId,
        status: "cancelled",
        ...values,
      },
      async (err, res) => {
        if (res) {
          const query = new Parse.Query("order");
          query.equalTo("objectId", orderId);

          const user = await query.first();



          const notification = await axios.post(
            "https://dev2.munchies.com.bd/api/auth/v1/user/notify",
            {
              topic: [user.get("user").id],
              title: "Order Cancelled",
              body: `Your order has been cancelled. Order ID: ${orderId}`,
            }
          )
          if(notification){
            message.success("Notification sent to customer");
          }
          message.success("Order cancelled!");

          setLoading(false);
          setVisible(false);
        } else {
          message.error(err.message);
          setLoading(false);
        }
      }
    );
  };

  return (
    <Modal
      title="Cancel Order"
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      style={{ zIndex: "9999" }}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {!exceptional && (
          <Item
            name="rejection_reason"
            label="Cancel Reason"
            rules={[
              {
                required: true,
                message: "Please select a reason!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a reason"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(v) => {
                if (v === "Exceptional") {
                  setExceptional(true);
                  form.setFieldsValue({ rejection_reason: "" });
                }
              }}
            >
              {reasons.map((reason, index) => (
                <Select.Option key={index} value={reason}>
                  {reason}
                </Select.Option>
              ))}
            </Select>
          </Item>
        )}
        {exceptional && (
          <Item
            name="rejection_reason"
            label="Cancel Reason"
            rules={[
              {
                required: true,
                message: "Please explain why this order is cancelled!",
              },
            ]}
          >
            <Input placeholder="Explain here" />
          </Item>
        )}

        <Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </Modal>
  );
}
