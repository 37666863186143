import { useCallback, useContext, useEffect, useState } from "react";
import { UserListContext } from "../../pages/User/UserList";
import {
  Table,
  Switch,
  DatePicker,
  Button,
  InputNumber,
  Space,
  Image,
  Input,
  Popconfirm,
  message,
} from "antd";
import useSearch from "../inventory/utils/useSearch";
import UserLinks from "../Common/UserLinks";
import {
  SettingOutlined,
  CalendarOutlined,
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Header from "./Header";
import { localDate } from "../../utils";
import Parse from "parse";

const TextWrapper = ({ children, style = {}, ...rest }) => (
  <span
    style={{
      fontSize: "16px",
      letterSpacing: ".5px",
      color: "#3c3c3c",
      fontWeight: "500",
      ...style,
    }}
    {...rest}
  >
    {children}
  </span>
);

const PriorityOrder = ({ order, params }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(order);
  const { setPriorityOrder } = useContext(UserListContext);
  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(() => {
    setLoading(true);
    setPriorityOrder({ id: params.id, no: value }, () => {
      setLoading(false);
      setEdit(false);
    });
  }, [value]);

  return (
    <div>
      {edit ? (
        <Space>
          <InputNumber
            min={0}
            value={value}
            onChange={(value) => setValue(value)}
          />
          <Button
            danger
            onClick={() => setEdit(false)}
            icon={<CloseOutlined />}
            shape="circle"
            size="small"
          />
          <Button
            type="primary"
            onClick={submitHandler}
            loading={loading}
            icon={<CheckOutlined />}
            shape="circle"
            size="small"
          />
        </Space>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextWrapper>{order ?? 0}</TextWrapper>
          <Button
            onClick={() => setEdit(true)}
            icon={<EditOutlined />}
            shape="circle"
          />
        </div>
      )}
    </div>
  );
};

const Note = ({ note, params, id }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(note);
  const { setNote, setMarketingNote, setFeedbackNote } =
    useContext(UserListContext);
  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(() => {
    const setNotCallback = () => {
      setLoading(false);
    };
    setLoading(true);
    if (id === "note") {
      setNote({ id: params.id, note: value }, setNotCallback);
    }
    if (id === "marketing_note") {
      setMarketingNote(
        { id: params.id, marketing_note: value },
        setNotCallback
      );
    }
    if (id === "call_feedback") {
      setFeedbackNote({ id: params.id, call_feedback: value }, setNotCallback);
    }
    setEdit(false);
  }, [value]);

  return (
    <div>
      {edit ? (
        <Space>
          <Input
            style={{
              minWidth: "120px",
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <Button
            danger
            onClick={() => setEdit(false)}
            icon={<CloseOutlined />}
            shape="circle"
            size="small"
          />
          <Button
            type="primary"
            onClick={submitHandler}
            loading={loading}
            icon={<CheckOutlined />}
            shape="circle"
            size="small"
          />
        </Space>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextWrapper
            style={{
              background: "#fff",
              minWidth: "180px",
              padding: "3px 5px",
              borderRadius: "10px",
              textAlign: "center",
              marginRight: "10px",
              boxShadow: "0 0 5px 0px #0000001a",
            }}
          >
            {note || "-"}
          </TextWrapper>
          <Button
            onClick={() => setEdit(true)}
            icon={<EditOutlined />}
            shape="circle"
          />
        </div>
      )}
    </div>
  );
};

export default function UsersTable() {
  const {
    fetchUsers,
    users,
    activeHandler,
    selectedRowKeys,
    onSelectChange,
    downloadUsers,
    nidUpdated,
  } = useContext(UserListContext);
  const { loading, data } = users;
  const [, getColumnSearchProps] = useSearch();
  const userSelect = [
    "objectId",
    "username",
    "name",
    "phone",
    "email",
    "type",
    "active",
    "verified",
    "order_count",
    "device_info",
    "gender",
    "date_of_birth",
    "priorityOrder",
    "nid",
    "nid_verified",
    "note",
    "marketing_note",
    "call_feedback",
    "last_order_date",
  ];
  const [params, setParams] = useState({ limit: 100, select: userSelect });

  useEffect(() => {
    fetchUsers(params);
  }, [params]);

  const typeColors = {
    admin: { color: "black", background: "rgb(255 203 221 / 63%)" },
    customer: { color: "black", background: "rgb(33 137 41 / 22%)" },
    csr: { color: "white", background: "#b71c1c73" },
    manager: { color: "white", background: "#8921094a" },
    rider: { color: "white", background: "#00acc157" },
    partner: { color: "black", background: "rgb(255 165 0 / 32%)" },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const [nidLoading, setNidLoading] = useState("");
  // const { nidUpdated } = useContext(OrdersContext);

  const nidVerificationUpdate = async (id, verified) => {
    try {
      if (verified) {
        setNidLoading("verified");
      } else {
        setNidLoading("fake");
      }
      await Parse.Cloud.run("nidVerificationUpdate", {
        userId: id,
        verified,
      });
      message.success("NID verification updated successfully");
      setNidLoading("");
      nidUpdated(id);
    } catch (err) {
      message.error(err.message);
      setNidLoading("");
    }
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
  });

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "160px",
      ...getColumnSearchProps("username"),
      render: (text, { id, type }) => {
        return (
          <UserLinks id={id} type={type}>
            {text}
          </UserLinks>
        );
      },
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "160px",
      ...dateSearchProps("createdAt"),
      render: (date) => localDate(date),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "160px",
      filters: [
        { text: "Customer", value: "customer" },
        { text: "Admin", value: "admin" },
        { text: "Manager", value: "manager" },
        { text: "Rider", value: "rider" },
        { text: "Partner", value: "partner" },
        { text: "CSR", value: "csr" },
        { text: "Product Manager", value: "product manager" },
        { text: "Store Manager", value: "store manager" },
      ],
      render: (text) => (
        <TextWrapper style={{ textTransform: "capitalize", color: "black" }}>
          {text}
        </TextWrapper>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      ...getColumnSearchProps("name"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: 100,
      filters: [
        { text: "True", value: true },
        { text: "False", value: false },
      ],
      render: (active, { id }) => (
        <div>
          <Switch
            onChange={(active) => activeHandler({ id, active })}
            checked={active}
          />
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "device_info",
      key: "device_info",
      width: "160px",
      filters: [
        { text: "Android", value: "Android" },
        { text: "iOS", value: "iOS" },
        { text: "Website", value: "web" },
      ],
      render: (data) => data?.type || "Website",
    },
    {
      title: "NID",
      dataIndex: "nid_verified",
      key: "nid_verified",
      width: "200px",
      filters: [
        { text: "Verified", value: true },
        { text: "Not verified", value: false },
      ],
      render: (nid_verified, { nid, id }) =>
        nid?.length ? (
          <div>
            <Image preview src={nid[0]} width={100} />
            <div>{nid_verified ? "Verified" : "Not verified"}</div>
            {nid_verified && (
              <div className="btn">
                <Popconfirm
                  title="Are you sure to mark this user as fake?"
                  onConfirm={() => {
                    nidVerificationUpdate(id, false);
                  }}
                >
                  <Button
                    loading={nidLoading === "fake"}
                    type="danger"
                    shape="circle"
                    icon={<CloseOutlined />}
                  ></Button>
                </Popconfirm>
                <Button
                  onClick={() => {
                    nidVerificationUpdate(id, true);
                  }}
                  type="primary"
                  loading={nidLoading === "verified"}
                  shape="circle"
                  icon={<CheckOutlined />}
                ></Button>
              </div>
            )}
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "260px",
      render: (note, params) => <Note note={note} params={params} id="note" />,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "160px",
      ...getColumnSearchProps("phone"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "180px",
      ...getColumnSearchProps("email"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "100px",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: "180px",
      ...dateSearchProps("date_of_birth"),
      render: (date) => localDate(date),
    },
    {
      title: "Order Count",
      dataIndex: "order_count",
      key: "order_count",
      width: "160px",
      ...getColumnSearchProps("order_count"),
      onFilter: (value, record) => {
        return true;
        // if (!defaultFilter) return true;
        // return onFilter(value, record, dataIndex);
      },
      render: (number) => <TextWrapper>{number}</TextWrapper>,
    },

    {
      title: "Search Area",
      dataIndex: "area",
      key: "area",
      width: "200px",
      ...getColumnSearchProps("area", {
        defaultFilter: false,
        useSelect: true,
      }),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (_, { id, type }) => (
        <span>
          <UserLinks id={id} type={type}>
            <SettingOutlined />
          </UserLinks>
        </span>
      ),
    },
    {
      title: "Priority Order",
      dataIndex: "priorityOrder",
      key: "priorityOrder",
      width: "180px",
      ...getColumnSearchProps("priorityOrder"),
      render: (order, params) => (
        <PriorityOrder order={order} params={params} />
      ),
    },
    {
      title: "Last Order Date",
      dataIndex: "last_order_date",
      key: "last_order_date",
      width: "160px",
      ...dateSearchProps("last_order_date"),
      render: (date) => localDate(date),
    },
    {
      title: "Marketing Note",
      dataIndex: "marketing_note",
      key: "marketing_note",
      width: "260px",
      ...getColumnSearchProps("marketing_note"),
      render: (marketing_note, params) => (
        <Note note={marketing_note} params={params} id="marketing_note" />
      ),
    },
    {
      title: "Customer Feedback",
      dataIndex: "call_feedback",
      key: "call_feedback",
      width: "260px",
      ...getColumnSearchProps("call_feedback"),
      render: (feedback_note, params) => (
        <Note note={feedback_note} params={params} id="call_feedback" />
      ),
    },
  ];

  const onChange = (pagination, filters, sorter) => {
    setParams({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      type: filters.type ? filters.type : "",
      name: filters.name ? filters.name[0] : "",
      phone: filters.phone ? filters.phone[0] : "",
      email: filters.email ? filters.email[0] : "",
      username: filters.username ? filters.username[0] : "",
      active: filters.active,
      verified: filters.verified ? filters.verified[0] : "",
      createdAt: filters.createdAt?.[0],
      select: userSelect,
      platform: filters.device_info,
      gender: filters.gender,
      date_of_birth: filters.date_of_birth?.[0],
      area: filters.area,
      sort: {
        field: sorter.field,
        order: sorter.order,
      },
      nid_verified: filters.nid_verified,
      order_count: filters.order_count ? filters.order_count[0] : "",
      marketing_note: filters.marketing_note ? filters.marketing_note[0] : "",
      call_feedback: filters.call_feedback ? filters.call_feedback[0] : "",
      priorityOrder: filters.priorityOrder ? filters.priorityOrder[0] : "",
      last_order_date: filters.last_order_date?.[0],
    });
  };

  const mapUsers = (users) => {
    return users.map((user) => ({
      key: user.id,
      id: user.id,
      ...user,
    }));
  };
  return (
    <div>
      <Header />
      <div>
        <Button
          onClick={() => {
            downloadUsers(params);
          }}
          icon={<DownloadOutlined />}
          type="primary"
        >
          Download
        </Button>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        loading={loading}
        onRow={({ type }) => {
          return {
            style: {
              background: typeColors[type]?.background ?? "#fff",
            },
          };
        }}
        dataSource={mapUsers(data.results)}
        pagination={{
          total: data.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "500", "1000", "5000"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        scroll={{ x: 1000, y: 1000 }}
        onChange={onChange}
      />
    </div>
  );
}
