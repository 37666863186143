import {
  message,
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Upload,
  Form,
  Modal,
  InputNumber,
} from "antd";
import { useState, useEffect, useCallback } from "react";
import Parse from "parse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  InboxOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { getSignedUrl, uploadFile, deleteFile } from "../../api/media";
import { getImageKey } from "../../common/utils";
import { Link } from "react-router-dom";
import useSearch from "../../components/inventory/utils/useSearch";

function RewardItem(props) {
  const { id, name, image, idx, deleteItem } = props;
  return (
    <Draggable draggableId={id} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="item"
        >
          <Space>
            <img src={image} alt="" className="image" />
            <h3 className="title">{name}</h3>
          </Space>
          <Button
            onClick={() => deleteItem(idx)}
            type="danger"
            icon={<DeleteOutlined />}
            shape="circle"
          />
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

function NewRewardItem({ addNew }) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;
  const [file, saveFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const uploadOnChange = async ({ fileList }) => {
    const file = fileList[fileList.length - 1];
    if (file?.originFileObj) {
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

      file.thumbUrl = src;
      saveFile(file);
    }
  };

  const onSubmit = async ({ name }) => {
    try {
      setLoading(true);
      const { url } = await getSignedUrl(file.type, "rewards");
      await uploadFile({ url, file });
      const image = url.split("?")[0];
      addNew({ name, image }, () => {
        form.resetFields();
        saveFile(null);
        setOpen(false);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
      >
        Add New
      </Button>
      <Modal
        title="Add New Cuisine"
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        footer={null}
        width={500}
      >
        <Form form={form} onFinish={onSubmit}>
          <Item>
            <Upload.Dragger
              beforeUpload={() => false}
              style={{
                marginBottom: "10px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                background: "#fff",
                display: file ? "none" : "block",
              }}
              listType="picture"
              multiple={false}
              type="file"
              showUploadList={false}
              onChange={uploadOnChange}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Reward Image Upload</p>
            </Upload.Dragger>
            {file && (
              <Picture>
                <img src={file.thumbUrl} alt="" className="image" />
                <Button
                  icon={<CloseOutlined />}
                  shape="circle"
                  className="close-btn"
                  onClick={() => {
                    saveFile(null);
                  }}
                ></Button>
              </Picture>
            )}
          </Item>

          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Reward Name" />
          </Item>
          <Item>
            <Space>
              <Button loading={loading} htmlType="submit" type="primary">
                Submit
              </Button>
            </Space>
          </Item>
        </Form>
      </Modal>
    </div>
  );
}

const Picture = styled.div`
  position: relative;
  margin-bottom: 20px;

  .image {
    width: 100%;
    max-height: 260px;
    border-radius: 10px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

function Column({ name, params, itemUpdate }) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(params[name]);
  const [loading, setLoading] = useState(false);

  const updateHandler = async (value) => {
    if (params.ref instanceof Parse.Object) {
      try {
        setLoading(true);
        params.ref.set(name, value);

        const order_total = params.ref.get("order_total");
        const referral_count = params.ref.get("referral_count");

        const point = Math.floor(order_total + referral_count * 100);
        params.ref.set("point", point);
        await params.ref.save();
        setEdit(false);
        setLoading(false);
        itemUpdate(params.ref);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {!edit && (
        <Space>
          <div>{params[name]}</div>
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => setEdit(true)}
          />
        </Space>
      )}
      {edit && (
        <Space>
          <InputNumber value={value} onChange={setValue} min={0} />
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            onClick={() => setEdit(false)}
            danger
          />
          <Button
            loading={loading}
            icon={<CheckOutlined />}
            shape="circle"
            onClick={() => updateHandler(value)}
          />
        </Space>
      )}
    </div>
  );
}

export default function WorldCupLeaderBoard() {
  const [data, setData] = useState({
    loading: false,
    results: [],
    count: 0,
  });
  const [rewards, setRewards] = useState([]);
  const [rewardObj, setRewardObj] = useState(null);
  const [, searchParams] = useSearch();

  async function fetchData({ limit, skip, userIds }) {
    try {
      setData({ ...data, loading: true });
      const query = new Parse.Query("user_point")
        .limit(limit)
        .skip(skip)
        .select([
          "user.name",
          "user.image",
          "user.username",
          "point",
          "order_total",
          "order_count",
          "referral_count",
          "user.device_info.type",
        ])
        .withCount()
        .descending("point");

      if (userIds && userIds.length > 0) {
        query.containedIn(
          "user",
          userIds.map((id) => Parse.User.createWithoutData(id))
        );
      }

      const res = await query.find();

      setData({
        loading: false,
        results: res.results.map((item, i) => ({
          ...item.toJSON(),
          index: parseInt(skip) + (i + 1),
          key: item.id,
          ref: item,
        })),
        count: res.count,
      });
    } catch (err) {
      message.error(err.message);
      setData((data) => ({ ...data, loading: false }));
    }
  }

  const itemUpdate = (ref) => {
    setData((data) => ({
      ...data,
      results: data.results.map((item) => {
        if (item.ref.id === ref.id) {
          return {
            ...item,
            ...ref.toJSON(),
          };
        }
        return item;
      }),
    }));
  };

  const fetchRewards = async () => {
    try {
      const res = await new Parse.Query("config")
        .equalTo("key", "worldcup_campaign_reward")
        .first();
      if (res) {
        const value = res.get("value")?.list || [];
        setRewards(value);
        setRewardObj(res);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const saveRewards = useCallback(
    async (items) => {
      setRewards(items);
      if (rewardObj instanceof Parse.Object) {
        rewardObj.set("value", {
          list: items,
        });
        await rewardObj.save();
      }
    },
    [rewardObj]
  );

  const addNewReward = async (item, cb) => {
    try {
      item.id = new Date().getTime().toString().substring(6);
      const items = [...rewards, item];
      await saveRewards(items);

      if (typeof cb === "function") {
        cb();
      }
      return true;
    } catch (err) {
      message.error(err.message);
    }
  };

  const deleteRewardItem = useCallback(
    async (index) => {
      const item = rewards[index];
      if (item) {
        const items = rewards.filter((i, idx) => idx !== index);
        setRewards(items);
        await saveRewards(items);
        await deleteFile(getImageKey(item?.image));
      }
    },
    [rewards]
  );

  useEffect(() => {
    fetchData({ limit: 100, skip: 0 });
    fetchRewards();
  }, []);

  const columns = [
    {
      title: "Rank",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Image",
      dataIndex: "user",
      key: "image",
      render: (user) => {
        return (
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "100%",
              background: "#f7f7f7",
            }}
            src={user?.image}
            alt=""
          />
        );
      },
    },
    {
      title: "Username",
      dataIndex: "user",
      key: "name",
      ...searchParams("username"),
      onFilter: (value, record) => {
        return record.user?.name?.toLowerCase().includes(value.toLowerCase());
      },
      render: (user) => user?.name || "N/A",
    },
    {
      title: "Phone",
      dataIndex: "user",
      key: "username",
      ...searchParams("username"),
      onFilter: (value, record) => {
        return record.user?.username?.includes(value);
      },
      render: (user) => user?.username || "N/A",
    },
    {
      title: "Points",
      dataIndex: "point",
      key: "points",
    },
    {
      title: "Order Total",
      dataIndex: "order_total",
      key: "order_total",
      render: (order_total, params) => (
        <Column name="order_total" params={params} itemUpdate={itemUpdate} />
      ),
    },
    {
      title: "Order Count",
      dataIndex: "order_count",
      key: "order_count",
    },
    {
      title: "Referral Count",
      dataIndex: "referral_count",
      key: "referral_count",
      render: (referral_count, params) => (
        <Column name="referral_count" params={params} itemUpdate={itemUpdate} />
      ),
    },
    {
      //user plartform
      title: "Platform",
      dataIndex: "user",
      key: "platform",
      render: (user) => user?.device_info.type || "N/A",
    },
    {
      title: "Orders",
      dataIndex: "user",
      key: "orders",
      render: (user) => (
        <Link target="__blank" to={`/order/list?user=${user?.objectId}`}>
          Orders
        </Link>
      ),
    },
  ];

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const { index: startIndex } = source;
    const { index: endIndex } = destination;

    const newOrder = [...rewards];
    const [removed] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, removed);

    saveRewards(newOrder);
  };

  return (
    <div>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <h2>Love's Delight Leader Board</h2>
          <Table
            loading={data.loading}
            columns={columns}
            dataSource={data.results?.map((item, idx) => ({
              ...item,
              key: idx,
            }))}
            pagination={{
              total: data.count,
              defaultPageSize: 100,
              pageSizeOptions: [10, 50, 100, 200, 500, 1000],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              position: ["topLeft", "topRight"],
            }}
            scroll={{ x: 1000 }}
            onChange={async (pagination, filters) => {
              let userIds;
              if (filters.name) {
                const users = await new Parse.Query(Parse.User)
                  .matches("name", filters.name[0], "i")
                  .limit(20)
                  .select("objectId")
                  .find();
                userIds = users.map((user) => user.id);
              } else if (filters.username) {
                const users = await new Parse.Query(Parse.User)
                  .matches("username", filters.username[0], "i")
                  .limit(20)
                  .select("objectId")
                  .find();
                userIds = users.map((user) => user.id);
              }
              fetchData({
                limit: pagination.pageSize,
                skip: (pagination.current - 1) * pagination.pageSize,
                userIds,
              });
            }}
          />
        </Col>
        <Col md={8}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1>Reward List</h1>
              <NewRewardItem addNew={addNewReward} />
            </div>
            <Droppable droppableId="droppable">
              {(provided) => {
                return (
                  <RewardsStyle
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="rewards customScroll"
                  >
                    {rewards.map((item, i) => (
                      <RewardItem
                        key={item.id}
                        idx={i}
                        {...item}
                        deleteItem={deleteRewardItem}
                      />
                    ))}
                    {provided.placeholder}
                  </RewardsStyle>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
    </div>
  );
}

const RewardsStyle = styled.div`
  height: calc(100vh - 230px);
  padding: 5px 10px;

  .item {
    height: 80px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 10px;
    cursor: grab;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .image {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: black;
    }

    .title {
      margin-bottom: 0;
    }
  }
`;
