import { useContext, useState } from "react";
import { Button, Form, message, Select } from "antd";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import Parse from "parse";

export default function AssignRider({ close, id, rider, status }) {
  const { riders, assignRider } = useContext(OrdersContext);
  const [visible, setVisible] = useState(!Boolean(rider));

  const onFinish = ({ rider_id }) => {
    if (rider_id) {
      assignRider({ order_id: id, rider_id }, async(done) => {
        if (done) {
          const query = new Parse.Query("User");
          query.equalTo("objectId", rider_id)
          .select("username");
          const rider = await query.first();
         
          const notification = await axios.post(
            "https://dev2.munchies.com.bd/api/auth/v1/user/notify",
            {
              topic: [rider.get("username")],
              title: "New Order",
              body: `You have a new order to deliver. Order ID: ${id}`,
            }
          );
          if(notification){
            message.success("Notification sent to rider");
          }
          close();
          setVisible(false);
        }
      });
    }
  };

  return (
    <div className="assign-rider">
      {!visible && !["delivered", "cancelled"].includes(status) && (
        <div className="edit-btn">
          <Button onClick={(e) => setVisible(true)} icon={<EditOutlined />}>
            {" "}
            Edit
          </Button>
        </div>
      )}

      <div>
        {visible && !["delivered", "cancelled", "rejected"].includes(status) && (
          <Form
            name="rider-assign"
            initialValues={{
              rider: rider ? rider.id : "",
            }}
            onFinish={onFinish}
            style={{ marginBottom: "40px" }}
          >
            <Form.Item
              name="rider_id"
              style={{ marginBottom: "10px" }}
              rules={[
                {
                  required: true,
                  message: "Please select rider",
                },
              ]}
            >
              <Select
                placeholder="Select rider"
                clearIcon={true}
                size="large"
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
              >
                <Select.Option value="">Select Rider</Select.Option>
                {riders.data.map((rider, i) => (
                  <Select.Option
                    style={{
                      color: rider.rider_availability ? "green" : "red",
                    }}
                    key={i}
                    value={rider.id}
                  >
                    {rider.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
              >
                Assign
              </Button>
              {rider && (
                <Button
                  type="primary"
                  onClick={(e) => setVisible(false)}
                  size="large"
                  danger
                  style={{ width: "100%", marginTop: "5px" }}
                >
                  Cancel
                </Button>
              )}
            </Form.Item>
          </Form>
        )}

        {!visible && rider && (
          <div className="rider-details">
            <Link to={`/users/riders/${rider.id}`}>
              Rider name: <strong>{rider.get("name")}</strong>
            </Link>
            <div>
              Rider username: <strong>{rider.get("username")}</strong>
            </div>
          </div>
        )}
      </div>

      <div className="box-footer">
        <Button
          style={{ borderRadius: "10px" }}
          size="middle"
          type="dashed"
          danger
          onClick={close}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
