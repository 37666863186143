import { useState } from "react";
import {
  Drawer,
  Button,
  Form,
  Upload,
  Row,
  Col,
  message,
  Divider,
  Alert,
  Tooltip,
  Image,
} from "antd";
import Styled from "styled-components";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";
import useMedia from "../../hooks/useMedia";
import { compressedImage, getImageKey } from "../../common/utils";

function UploadForm({ state, setState, deleteHandler }) {
  const { loading, multiple = false } = state;
  const [form] = Form.useForm();
  const { multipleFileUpload } = useMedia();
  const [error, setError] = useState(null);

  const normFile = (e) => {
    state.fileList = e.fileList;
    setState({ ...state });
    return e && e.fileList;
  };

  const onFinish = async (values) => {
    state.loading = true;
    setState({ ...state });

    let { files } = values;

    if (!files || files.length === 0) {
      message.error("Please select files to upload");
      return;
    }

    files = files.map((file) => {
      file.callback = (index, progress, url) => {
        if (progress === 100) {
          if (!state.multiple) {
            if (state.images[0]) {
              deleteHandler(state.images[0]);
              state.images.splice(0, 1);
              setState({ ...state });
            }
          }
          state.images.unshift(url.split("?")[0]);
          setState({ ...state });
          const formImages = form.getFieldValue("files");
          formImages.splice(index, 1);
          form.setFieldsValue({ files: [...formImages] });
        }
      };
      return file;
    });

    multipleFileUpload(files, (err, success) => {
      if (err) {
        setError(err);
      }

      if (success) {
        setError(null);
      }

      state.loading = false;
      setState({ ...state });
    });
  };

  return (
    <Form
      form={form}
      name="add-new-media"
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
    >
      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon closable />
        </Form.Item>
      )}
      <Form.Item
        name="files"
        valuePropName="fileList"
        noStyle
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: "Please upload your file!",
          },
        ]}
      >
        <Upload.Dragger
          beforeUpload={() => false}
          style={{ marginBottom: "10px" }}
          name="files"
          listType="picture"
          multiple={multiple}
          type="file"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item style={{ marginTop: "10px" }}>
        <Button
          size="large"
          id="media-submit"
          loading={loading}
          disabled={loading}
          htmlType="submit"
          type="primary"
          style={{ width: "100%" }}
        >
          Upload To Cloud
        </Button>
      </Form.Item>
    </Form>
  );
}

function Preview({ state, deleteHandler, onOk }) {
  const { images } = state;
  return (
    <PreviewWrapper span={12}>
      <Alert
        message="Be careful when you delete images, it will be deleted from the original cloud source! There is no way to get back images if they became deleted once."
        type="warning"
        showIcon
        closable
      />
      <Divider orientation="left">Uploaded images</Divider>
      <Row gutter={[16, 24]}>
        {images.map((src, index) => {
          return (
            <Col span={8} key={index}>
              <div className="image-wrapper">
                <Image
                  width="100%"
                  height="100%"
                  src={src}
                  alt="product"
                  preview={{ src }}
                />
                <Tooltip
                  placement="leftTop"
                  title="Delete from origin"
                  color="blue"
                >
                  <div onClick={() => deleteHandler(src)} className="close-x">
                    <CloseOutlined />
                  </div>
                </Tooltip>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="footer">
        <Button size="large" type="primary" onClick={onOk}>
          Done
        </Button>
      </div>
    </PreviewWrapper>
  );
}

export default function UploadMedia(props) {
  const { state, setState, successCallback } = props;
  const { visible, images } = state;
  const { deleteFile } = useMedia();

  const deleteHandler = (url) => {
    const key = getImageKey(url);
    deleteFile(key, (err, res) => {
      if (err) {
        message.error(err);
      } else if (res) {
        const index = state.images.findIndex(
          (image) => getImageKey(image) === key
        );
        if (index > -1) {
          state.images.splice(index, 1);
          setState({ ...state });
        }
      }
    });
  };
  const onCloseHandler = () => {
    setState({ ...state, visible: false });
  };

  const taskDone = () => {
    if (successCallback && typeof successCallback === "function") {
      successCallback(state.images);
    }
    onCloseHandler();
  };

  return (
    <AddNewWrapper>
      {props.countText && (
        <div style={{ color: "green" }}>
          <strong>{images.length}</strong> image selected!
        </div>
      )}
      <Drawer
        zIndex={1012}
        width="100%"
        title="Upload media"
        placement="right"
        onClose={onCloseHandler}
        visible={visible}
        style={{ visibility: visible ? "visible" : "hidden" }}
      >
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <UploadForm {...props} deleteHandler={deleteHandler} />
          </Col>
          <Preview {...props} onOk={taskDone} deleteHandler={deleteHandler} />
        </Row>
      </Drawer>
    </AddNewWrapper>
  );
}

UploadMedia.defaultProps = {
  countText: true,
};

const AddNewWrapper = Styled.div`
    padding: 10px;
    display: flex;
    align-items: center;

    .add-new-btn{
        margin-left: auto;
        display: flex;
        width: 120px;
        align-items: center;
        justify-content: center;
    }
`;

const PreviewWrapper = Styled(Col)`
    position: fixed;
    top: 0;
    right: 0;
    height: calc(100vh - 70px);
    margin-top: 70px;
    padding: 0 20px 20px 20px;
    width: 48%;
    overflow-y: auto;
    margin-right: 10px;

    .main-title{
        font-size: 22px;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
    }

    .footer{
        width: 100%;
        position: absolute;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 50px;
        
        button{
            width: 100px;
        }
    }


    .image-wrapper{
        width: 100%;
        position: relative;
        overflow: hidden;
        min-height: 100px;
        background: lightslategray
        
        img{
            box-shadow: 0px 5px 10px rgba(0,0,0,0.1); 
        }

        .close-x{
            position: absolute; 
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: red;
            margin: 10px;
            transition: all 0.2s ease;

            &:hover{
                background: #e83e8c;
                color: #fff;
            }
        }
    }
`;
