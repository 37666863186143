import {
  BiHome,
  BiCart,
  BiBox,
  BiCycling,
  BiUser,
  BiCreditCard,
  BiCollection,
  BiStore,
  BiRocket,
  BiCarousel,
  BiCog,
} from "react-icons/bi";
import { FaHistory, FaWarehouse } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { GiCook } from "react-icons/gi";
import { ApartmentOutlined } from "@ant-design/icons";

export const menu = [
  {
    title: "Dashboard",
    icon: <BiHome />,
    link: "/dashboard",
    key: "dashboard",
  },
  {
    title: "Campaign",
    icon: <ApartmentOutlined />,
    base: "/campaign",
    items: [
      {
        title: "Love's Delight Campaign",
        icon: "",
        link: "/world-cup-leader-board",
        key: "world_cup_leader_board",
      },
    ],
  },
  {
    title: "Reports",
    icon: <IoMdAnalytics />,
    base: "/report",
    items: [
      {
        title: "Searches",
        icon: "",
        link: "/searches",
        key: "searches",
      },
      {
        title: "Order Analytics",
        icon: "",
        link: "/order-analytics",
        key: "order_analytics",
      },
      {
        title: "Product/Category Sales",
        icon: "",
        link: "/product-category-sales",
        key: "product_category_sales_report",
      },
      {
        title: "Inventory Report",
        icon: "",
        link: "/inventory",
        key: "inventory_report",
      },
      {
        title: "Restaurant Sales",
        icon: "",
        link: "/restaurant-sales",
        key: "restaurant_sales_report",
      },
      {
        title: "Prep Times",
        icon: "",
        link: "/restaurant-prep-times",
        key: "restaurant_prep_times",
      },
    ],
  },
  {
    title: "Orders",
    icon: <BiCart />,
    base: "/order",
    items: [
      {
        title: "Order Processor",
        icon: "",
        link: "/processor",
        key: "order_processor",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "order_list",
      },
      {
        title: "Issues",
        icon: "",
        link: "/issues",
        key: "order_issues",
      },
      {
        title: "Archived Orders",
        icon: "",
        link: "/archived",
        key: "archived_orders",
      },
    ],
  },
  {
    title: "Product",
    icon: <BiBox />,
    base: "/product",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "product_list",
      },
      {
        title: "Pending Approval",
        icon: "",
        link: "/approval",
        key: "pending_product_approval",
      },
      {
        title: "Update Requests",
        icon: "",
        link: "/change-requests",
        key: "product_change_request",
      },
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_product",
      },
      {
        title: "Categories",
        icon: "",
        link: "/categories",
        key: "categories",
      },
    ],
  },
  {
    title: "Restaurants",
    icon: <BiStore />,
    base: "/restaurant",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "restaurant_list",
      },
      {
        title: "Notification",
        icon: "",
        link: "/notification",
        key: "restaurant_notification",
      },
      {
        title: "Noticeboard",
        icon: "",
        link: "/noticeboard",
        key: "restaurant_noticeboard",
      },
      {
        title: "Sales",
        icon: "",
        link: "/sales",
        key: "restaurant_sales",
      },
      {
        title: "Add",
        icon: "",
        link: "/add",
        key: "add_restaurant",
      },
      {
        title: "Connected Hub",
        icon: "",
        link: "/hub-list",
        key: "restaurant_hub_list",
      },
    ],
  },
  {
    title: "Rider",
    icon: <BiCycling />,
    base: "/rider",
    items: [
      {
        title: "Joining Request",
        icon: "",
        link: "/joining",
        key: "rider_joining",
      },
      {
        title: "Registration",
        icon: "",
        link: "/registration",
        key: "rider_registration",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "rider_list",
      },
      {
        title: "Tracking",
        icon: "",
        link: "/tracking",
        key: "rider_tracking",
      },
      {
        title: "Dashboard",
        icon: "",
        link: "/dashboard",
        key: "rider_dashboard",
      },
      {
        title: "Salary Sheet",
        icon: "",
        link: "/salary-sheet",
        key: "rider_salary_sheet",
      },
      {
        title: "Leader Board",
        icon: "",
        link: "/leaderboard",
        key: "rider_leaderboard",
      },
      {
        title: "Settings",
        icon: "",
        link: "/settings",
        key: "rider_settings",
      },
      {
        title: "Payment Request",
        icon: "",
        link: "/payment_request",
        key: "rider_payment_request",
      },
    ],
  },
  {
    title: "User",
    icon: <BiUser />,
    base: "/user",
    items: [
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_user",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "user_list",
      },
      {
        title: "History",
        icon: "",
        link: "/history",
        key: "user_history",
      },
      {
        title: "Noticeboard",
        icon: "",
        link: "/notice",
        key: "user_notice",
      },
      {
        title: "User Dashboard",
        icon: "",
        link: "/dashboard",
        key: "user_dashboard",
      },
      {
        title: "OTP",
        icon: "",
        link: "/otp",
        key: "otp_store",
      },
    ],
  },
  {
    title: "Sections",
    icon: <BiCollection />,
    base: "/sections",
    key: "sections",
    items: [
      {
        title: "Dashboard",
        icon: "",
        link: "/dashboard",
        key: "sections_dashboard",
      },
      {
        title: "Scheduling",
        icon: "",
        link: "/scheduling",
        key: "sections_scheduling",
      },
      {
        title: "Slots Map",
        icon: "",
        link: "/slots-map",
        key: "section_slots_map",
      },
    ],
  },
  {
    title: "Story",
    icon: <FaHistory />,
    link: "/story",
    key: "story",
  },
  {
    title: "Payments",
    icon: <BiCreditCard />,
    base: "/payments",
    items: [
      {
        title: "Payments",
        icon: "",
        link: "/",
        key: "payments",
      },
      {
        title: "Invoice History",
        icon: "",
        link: "/history",
        key: "invoice_history",
      },
      {
        title: "Download",
        icon: "",
        link: "/download",
        key: "download_payments",
      },
    ],
  },
  {
    title: "Inventory",
    icon: <FaWarehouse />,
    base: "/inventory",
    items: [
      {
        title: "Barcodes",
        icon: "",
        link: "/barcodes",
        key: "inventory_barcodes",
      },
      {
        title: "Scanning",
        icon: "",
        link: "/scanning",
        key: "inventory_scanning",
      },
      {
        title: "Add",
        icon: "",
        link: "/add",
        key: "add_inventory",
      },
      {
        title: "History",
        icon: "",
        link: "/list",
        key: "inventory_history",
      },
    ],
  },
  {
    title: "Promo",
    icon: <BiRocket />,
    base: "/promo",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "promo_list",
      },
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_promo",
      },
    ],
  },
  {
    title: "Carousel",
    icon: <BiCarousel />,
    link: "/carousel",
    key: "carousel",
  },
  {
    title: "Promotional Banner",
    icon: <BiCarousel />,
    link: "/promotional-banner",
    key: "promotional_banner",
  },
  {
    title: "Hub",
    icon: <BiCog />,
    base: "/hub",
    items: [
      {
        title: "Create New",
        icon: "",
        link: "/create-new",
        key: "add_hub",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "hub_list",
      },
      {
        title: "Area list",
        icon: "",
        link: "/areas",
        key: "area_list",
      },
      {
        title: "Settings",
        icon: "",
        link: "/settings",
        key: "hub_settings",
      },
      {
        title: "Zone Control",
        icon: "",
        link: "/zone-control",
        key: "hub_zone_control",
      },
      {
        title: "Geo Areas",
        icon: "",
        link: "/geo-areas",
        key: "hub_geo_areas",
      },
    ],
  },
  {
    title: "Survey",
    icon: <IoMdAnalytics />,
    base: "/survey",
    items: [
      {
        title: "Create New",
        icon: "",
        link: "/create",
        key: "add_survey",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "survey_list",
      },
    ],
  },
  {
    title: "Config",
    icon: <BiCog />,
    base: "/config",
    items: [
      {
        title: "General",
        icon: "",
        link: "/",
        key: "config_general",
      },
      {
        title: "Role Settings",
        icon: "",
        link: "/role-settings",
        key: "config_role_settings",
      },
      {
        title: "Profile",
        icon: "",
        link: "/admin/me",
        key: "profile",
      },
      {
        title: "Logs",
        icon: "",
        link: "/logs",
        key: "logs",
      },
    ],
  },
];

export default menu;
